<template>
  <Modal
    v-model:visible="visible"
    header="Enter Routing Number to Verify Your Bank"
    :modal="true"
    :draggable="false"
    append-to="self"
    @hide="closeModal"
  >
    <p :class="$style.description">
      This verification will ensure we are connecting to the correct banking
      institution.
    </p>

    <div :class="$style.details">
      <div class="c-form">
        <div class="c-form__row">
          <InputTextGroup
            v-model="routingNumber"
            name="routingNumber"
            label="Routing Number"
          />
          <a :class="$style['help-link']" href="#" targer="_blank"
            >How to find routing number</a
          >
        </div>
        <div class="c-form__controls">
          <DSButton
            label="Cancel"
            class="p-button-outlined"
            @click="closeModal"
          />
          <DSButton
            label="Submit"
            type="submit"
            :disabled="!routingNumber"
            @click="completedModal"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@clearbanc/clear-components/dialog';
import InputTextGroup from '@clearbanc/clear-components/inputtextgroup';
import DSButton from '@clearbanc/clear-components/button';

export default {
  emits: ['close', 'routingNumber'],
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    Modal,
    InputTextGroup,
    DSButton,
  },
  data() {
    return {
      routingNumber: null,
    };
  },
  methods: {
    closeModal() {
      this.routingNumber = null;
      this.$emit('close');
    },
    completedModal() {
      this.$emit('routingNumber', this.routingNumber);
      this.closeModal();
    },
  },
};
</script>

<style lang="less" module>
.description {
  margin: 8px 0 0 0;
}

.details {
  margin-top: 40px;
}

.help-link {
  text-decoration: underline;
  margin: 10px 0 0 0;
  display: inline-block;
}
</style>
