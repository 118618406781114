import _ from 'lodash';
import {
  ENTITY_TYPE,
  BUSINESS_DOC_TYPE,
  BANK_ACCOUNT_DOC_TYPE,
} from '@clearbanc/data-common-types';
import { buildApiActions } from '@/utils/vuex-api-utils';
import {
  diligenceService,
  diligenceServiceVerifications,
  businessDiligenceApi,
} from '../../utils/diligence-service';
import { PERSONA_STATUS, PERSONA_ENTITY } from '../../data/persona';

const DOC_ERROR_MESSAGES = {
  id_user: 'Missing user government ID',
  corp_docs: 'Missing business corporation docs',
  ein_doc: 'Missing business EIN document',
  bank_statements: 'Missing business bank statements',
  asset_liability_doc: 'Missing assets and libilities document',
};

function isOwnerGovtIDRequired(ownerRoles) {
  const relevantRoles = ['owner', 'signatory', 'sole-owner-and-director'];
  if (ownerRoles == null) {
    return false;
  }
  return relevantRoles.some((role) => ownerRoles.includes(role));
}

export default {
  state: {
    uploads: {},
    currentUpload: {},
    personaVerifications: {},
    fiscalDocumentChecks: {},
    documentRequests: [],
    activeDocumentRequests: [],
    hasActiveDocumentRequests: false,
    isBrowserUploadingFile: false,
  },
  getters: {
    uploads: (state) => _.values(state.uploads),
    personaVerifications: (state) =>
      _.values(state.personaVerifications).filter(Boolean),
    currentUpload: (state) => state.currentUpload,
    isBrowserUploadingFile: (state) => state.isBrowserUploadingFile,
    isUploadInProgress: (state, getters) => {
      const userUploadRequest = getters.requestStatus('ADD_USER_UPLOAD');
      return state.isBrowserUploadingFile || userUploadRequest.isPending;
    },

    // allows us to search for matching several keys, but will work for array of possible values
    // ex: `selectedUserUploadsMatching({ enitity: 'user', type: ['passport', 'id'] })`
    uploadsMatching: (state, getters) => (match) => {
      const matchingDocs = [];
      _.each(getters.uploads, (upload) => {
        // ugly matching thing so we can match on array of types
        if (
          _.every(
            _.map(match, (matchVal, matchKey) => {
              if (_.isArray(matchVal))
                return matchVal.includes(upload[matchKey]);
              return matchVal === upload[matchKey];
            }),
          )
        ) {
          matchingDocs.push(upload);
        }
      });
      return matchingDocs;
    },

    bankUploadMatcher: (state, getters, rootState, rootGetters) => {
      return rootGetters.isDiligenceServiceReadDataEnabled
        ? {
            externalIdType: ENTITY_TYPE.BANK_ACCOUNT,
            documentType: BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS,
          }
        : {
            entity: ENTITY_TYPE.BANK_ACCOUNT,
            type: BANK_ACCOUNT_DOC_TYPE.BANK_STATEMENTS,
          };
    },

    financialStatementUploadMatcher: (
      state,
      getters,
      rootState,
      rootGetters,
    ) => {
      return rootGetters.isDiligenceServiceReadDataEnabled
        ? {
            externalIdType: ENTITY_TYPE.BUSINESS,
            documentType: BUSINESS_DOC_TYPE.FINANCIAL_STATEMENTS,
          }
        : {
            entity: ENTITY_TYPE.BUSINESS,
            type: BUSINESS_DOC_TYPE.FINANCIAL_STATEMENTS,
          };
    },

    incorporationUploadMatcher: (state, getters, rootState, rootGetters) => {
      return rootGetters.isDiligenceServiceReadDataEnabled
        ? {
            externalIdType: ENTITY_TYPE.BUSINESS,
            documentType: BUSINESS_DOC_TYPE.CORP_DOCS,
          }
        : { entity: ENTITY_TYPE.BUSINESS, type: BUSINESS_DOC_TYPE.CORP_DOCS };
    },

    einUploadMatcher: (state, getters, rootState, rootGetters) => {
      return rootGetters.isDiligenceServiceReadDataEnabled
        ? {
            externalIdType: ENTITY_TYPE.BUSINESS,
            documentType: BUSINESS_DOC_TYPE.EIN_DOC,
          }
        : {
            entity: ENTITY_TYPE.BUSINESS,
            type: BUSINESS_DOC_TYPE.EIN_DOC,
          };
    },

    assetLiabilitiesUploadMatcher: (state, getters, rootState, rootGetters) => {
      return rootGetters.isDiligenceServiceReadDataEnabled
        ? {
            externalIdType: ENTITY_TYPE.BUSINESS,
            documentType: BUSINESS_DOC_TYPE.ASSET_LIABILITY_DOC,
          }
        : {
            entity: ENTITY_TYPE.BUSINESS,
            type: BUSINESS_DOC_TYPE.ASSET_LIABILITY_DOC,
          };
    },

    uploadRequirements: (state, getters, rootState, rootGetters) => {
      const corpDocs = getters.uploadsMatching(
        getters.incorporationUploadMatcher,
      );
      const einDoc = getters.uploadsMatching(getters.einUploadMatcher);
      const bankDocs = getters.uploadsMatching(getters.bankUploadMatcher);
      const userIdUploads = getters.uploadsMatching(
        rootGetters.isDiligenceServiceReadDataEnabled
          ? {
              externalIdType: ENTITY_TYPE.USER,
              isGovtID: true,
            }
          : {
              entity: ENTITY_TYPE.USER,
              isGovtID: true,
            },
      );
      const assetLiabilityDoc = getters.uploadsMatching(
        getters.assetLiabilitiesUploadMatcher,
      );
      const ownerRequirements = {
        id_user: {
          required: true,
          complete: userIdUploads.length > 0,
        },
      };

      _.each(rootGetters.businessOwners, (owner) => {
        const idUploads = getters.uploadsMatching(
          rootGetters.isDiligenceServiceReadDataEnabled
            ? {
                externalIdType: ENTITY_TYPE.OWNER,
                externalId: owner.id,
                isGovtID: true,
              }
            : {
                entity: ENTITY_TYPE.OWNER,
                metaId: owner.id,
                isGovtID: true,
              },
        );
        ownerRequirements[`id_${owner.id}`] = {
          required: isOwnerGovtIDRequired(owner.jobRoles),
          complete: idUploads.length > 0,
        };
      });

      return {
        corp_docs: {
          required: true,
          complete: corpDocs.length > 0,
        },
        ein_doc: {
          required: _.get(rootGetters.business, 'corpCountry') === 'US',
          complete: einDoc.length > 0,
        },
        bank_statements: {
          required: _.get(rootGetters.business, 'corpCountry') === 'AU',
          complete:
            _.get(rootGetters.business, 'corpCountry') === 'AU' &&
            bankDocs.length > 0,
        },
        asset_liability_doc: {
          required: ['DE', 'AT'].includes(
            _.get(rootGetters.business, 'corpCountry'),
          ),
          complete:
            ['DE', 'AT'].includes(_.get(rootGetters.business, 'corpCountry')) &&
            assetLiabilityDoc.length > 0,
        },
        ...ownerRequirements,
      };
    },

    uploadsComplete: (state, getters) =>
      _.every(getters.uploadRequirements, (status, name) => {
        // more logic here around us (or our KYC providers) rejecting uploads
        if (!status.required) return true;
        return status.complete;
      }),

    uploadsErrors: (state, getters) => {
      const errors = [];
      _.each(getters.uploadRequirements, (docRequirements, docKey) => {
        if (docRequirements.required && !docRequirements.complete) {
          errors.push(DOC_ERROR_MESSAGES[docKey]);
        }
      });
    },

    personaCompletedCorporationVerification: (state, getters) => {
      return getters.personaVerifications.filter(
        (verification) =>
          verification.entityType === 'business' &&
          [PERSONA_STATUS.COMPLETE, PERSONA_STATUS.FAILED].includes(
            verification.inquiryStatus,
          ),
      );
    },

    isPersonaCorporationVerificationComplete: (state, getters) => {
      return getters.personaCompletedCorporationVerification.length > 0;
    },

    personaOwnerVerification:
      (state, getters, rootState, rootGetters) => (ownerId) => {
        return !!getters.personaVerifications.find((verification) => {
          return (
            (verification.entityType === PERSONA_ENTITY.OWNER ||
              verification.entityType === PERSONA_ENTITY.USER) &&
            verification.entityId === String(ownerId) &&
            [PERSONA_STATUS.COMPLETE, PERSONA_STATUS.FAILED].includes(
              verification.inquiryStatus,
            )
          );
        });
      },

    personaInquiryStatus:
      (state, getters, rootState, rootGetters) => (ownerId) => {
        const inquiries = getters.personaVerifications.filter(
          (verification) =>
            (verification.entityType === PERSONA_ENTITY.OWNER ||
              verification.entityType === PERSONA_ENTITY.USER) &&
            verification.entityId === String(ownerId),
        );

        if (inquiries.length <= 1) {
          return inquiries[0]?.inquiryStatus;
        }

        const sortedInquiries = inquiries.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        );

        return sortedInquiries[0].inquiryStatus;
      },

    fiscalDocumentChecks: (state) => state.fiscalDocumentChecks,
    documentRequests: (state) => state.documentRequests,
    statementsRequired: (state, getters, rootState, rootGetters) => {
      return (
        rootGetters.isHeronPdfAutomationEnabled &&
        rootGetters.businessOnPlaidBypass &&
        !rootGetters.pdfStatementsCompletionDetails.isComplete &&
        !rootGetters.primaryIsPlaidAccount
      );
    },
    activeDocumentRequests: (state, getters, rootState, rootGetters) => {
      let bankStatementsRequest;
      let financialStatementsRequest;

      if (
        rootGetters.isHeronPdfAutomationEnabled &&
        rootGetters.isBusinessProfileComplete &&
        rootGetters.businessOnPlaidBypass &&
        !rootGetters.pdfStatementsCompletionDetails.isComplete &&
        !rootGetters.primaryIsPlaidAccount
      ) {
        bankStatementsRequest = {
          type: 'bank_statements',
          status: 'requested',
          message: 'Please Upload the last 6 months of bank statements',
          createdAt: new Date(),
        };
      }

      if (
        rootGetters.isInFinancialDocsSegment &&
        rootGetters.fiscalStartDate &&
        !rootGetters.fiscalDocumentsUpToDate
      ) {
        financialStatementsRequest = {
          type: 'financial_statements',
          status: 'requested',
          message:
            'Please Upload the most recent complete quarter and fiscal year financial statements',
          createdAt: new Date(),
        };
      }

      return [
        ...state.activeDocumentRequests,
        ...(bankStatementsRequest ? [bankStatementsRequest] : []),
        ...(financialStatementsRequest ? [financialStatementsRequest] : []),
      ];
    },
    hasActiveDocumentRequests: (state, getters) =>
      !!getters.activeDocumentRequests.length,
  },
  ...buildApiActions(
    {
      // fetch all uploads for a business
      FETCH_USER_UPLOADS: {
        action: (ctx, payload) => {
          return ctx.rootGetters.isDiligenceServiceReadDataEnabled
            ? {
                requestFunc: async () =>
                  diligenceService.documentsControllerFindAll(
                    ctx.rootGetters.businessId,
                  ),
              }
            : {
                method: 'get',
                url: `/businesses/${ctx.rootGetters.businessId}/uploads`,
              };
        },
        mutation: (state, { response }) => {
          state.uploads = _.keyBy(response, 'id');
        },
      },

      // gets the pre-signed S3 upload URL
      GET_SIGNED_UPLOAD_URL: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/businesses/${ctx.rootGetters.businessId}/uploads`,
          params: {
            businessId: ctx.rootGetters.businessId,
            sign: true,
            ...payload,
          },
          returnResponse: true, // returns the api response directly from the action!
        }),
      },

      // adds the upload after it has actually been uploaded to S3 directly from the browser
      ADD_USER_UPLOAD: {
        action: (ctx, payload) => ({
          method: 'post',
          url: `/businesses/${ctx.rootGetters.businessId}/uploads`,
          params: { businessId: ctx.rootGetters.businessId, ...payload },
          returnResponse: true,
        }),
        mutation: (state, { response }) => {
          state.uploads[response.id] = response;
        },
      },

      UPDATE_USER_UPLOAD: {
        action: (ctx, payload) => ({
          method: 'patch',
          url: `/uploads/${payload.id}`,
          params: { ...payload },
          returnResponse: true,
        }),
        mutation: (state, { response }) => {
          const upload = response[0];
          state.uploads[upload.id] = upload;
        },
      },

      // fetch specific upload details - this can include a pre-signed download URL
      GET_USER_UPLOAD_DETAILS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/uploads/${payload.id}`,
          params: _.omit(payload, 'id'),
          returnResponse: true,
        }),
        mutation: (state, { response }) => {
          state.currentUpload = response;
          state.uploads[response.id] = response;
        },
      },

      // delete an upload
      DELETE_USER_UPLOAD: {
        action: (ctx, payload) => ({
          method: 'delete',
          url: `/uploads/${payload.id}`,
        }),
        mutation: (state, { response, payload }) => {
          delete state.uploads[payload.id];
        },
      },

      GET_PERSONA_INQUIRY: {
        action: (ctx, payload) => ({
          requestFunc: async () =>
            diligenceServiceVerifications.documentVerificationsControllerFindPersonaInquiry(
              { ...payload, businessId: String(ctx.rootGetters.businessId) },
            ),
          returnResponse: true,
        }),
        mutation: (state, { response }) => {
          state.personaVerifications[response.id] = response;
        },
      },

      GET_PERSONA_VERIFICATIONS: {
        action: (ctx) => ({
          requestFunc: async () =>
            diligenceServiceVerifications.documentVerificationsControllerFindDocumentVerificationsByBusinessId(
              String(ctx.rootGetters.businessId),
            ),
          returnResponse: true,
        }),
        mutation: (state, { response }) => {
          state.personaVerifications = response;
        },
      },

      SEND_PERSONA_INQUIRY_EMAIL: {
        action: (ctx, payload) => ({
          requestFunc: () =>
            diligenceServiceVerifications.documentVerificationsControllerCreateOwnerDocumentVerification(
              {
                ...payload,
                businessId: String(ctx.rootGetters.businessId),
                businessName: ctx.rootGetters.businessName,
              },
            ),
          returnResponse: true,
        }),
      },

      GET_FISCAL_DOCUMENT_CHECKS: {
        action: (ctx, payload) => ({
          requestFunc: async () => {
            return businessDiligenceApi.businessDiligenceControllerFiscalDocumentChecks(
              payload.businessId
                ? payload.businessId
                : ctx.rootGetters.businessId,
            );
          },
          returnResponse: true,
        }),
        mutation: (state, { response }) => {
          state.fiscalDocumentChecks = response;
        },
      },

      GET_DOCUMENT_REQUESTS: {
        action: (ctx, payload) => ({
          method: 'get',
          url: `/businesses/${ctx.rootGetters.businessId}/document-requests`,
        }),
        mutation: (state, { response }) => {
          state.documentRequests = response;
          state.activeDocumentRequests = response.filter(
            (doc) => doc.status === 'requested',
          );
        },
      },
    },
    {
      mutations: {
        UPDATE_DOC_TYPE_PARAMS: (state, params) => {
          Object.values(state.uploads).forEach((file, index) => {
            if (file.id === params.id) {
              state.uploads[params.id] = {
                ...state.uploads[params.id],
                ...params,
              };
            }
          });
        },
        SET_BROWSER_UPLOADING_FILE: (state, isBrowserUploadingFile) => {
          state.isBrowserUploadingFile = isBrowserUploadingFile;
        },
      },
    },
  ),
};
